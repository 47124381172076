<template>
  <div class="films">
      <transition name="fade">
          <div class="wrapper-text" v-if="loaded">
              <page-content :blocks="content"></page-content>
          </div>
      </transition>
  </div>
</template>

<script>

import PageContent from '@/components/PageContentComponent.vue';

export default {
  name: 'Films',
  components: {
      PageContent
  },
  props: {},
  data () {
      return {
          loaded: false
      };
  },
  computed: {
      // a computed getter
      content() {
          let page = this.$store.getters.getPageBySlug('filme');
          if (page)
              return page.blocks;
          else
              return [];
      }
  },
  mounted(){
      this.loaded = true;
  },
}
</script>
